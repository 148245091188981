import one from './assets/1.jpg';
import two from './assets/2.jpg';
import three from './assets/3.jpg';
import four from './assets/4.jpg';
import five from './assets/5.JPG'
import seven from './assets/7.JPG'
import eight from './assets/8.JPG'
import nine from './assets/9.JPG'
import ten from './assets/10.JPG'
import eleven from './assets/11.jpg'
import twelve from './assets/12.PNG'
import thirteen from './assets/13.jpg'

export const imageDetails = [
  {
    image: one,
    description: 'This was the first day we went out, and you basically followed me round lagos mainland to buy things that I really did not need, and the one I needed I could not get it that day'
  },
  {
    image: two,
    description: 'This was the day we went to Nike art gallery and we kinda snuck into one room and found some artifacts and you decided to try one lol'
  },
  {
    image: three,
    description: 'This was the day we went to stories for the first time, after checking that place for the buffet and the patio and we were not satisfied, especially that hot sun at the patio'
  },
  {
    image: four,
    description: 'This was the day we were both baked, LMAO! and we still went to paint that day 😂'
  },
  {
    image: five,
    description: 'Hmm our first wedding together as guys, also the day we first kissed, even if you had to drag me outside for that (sorry about that 🤦🏿‍♂️)'
  },
  {
    image: seven,
    description: 'This was the day I asked you to be mine at SPAR supermarket. We were already late to the birthday party, and still fooling around in the bolt.'
  },
  {
    image: eight,
    description: 'Our first weekend together, you know the rest LOL. We are definitely going to recreate this weekend and make it way better'
  },
  {
    image: nine,
    description: 'I look so much at peace being here with you, this was the day we went to the spa, to get rubbed down lol 😂'
  },
  {
    image: ten,
    description: 'This is also the day we went to the spa, this photograph is pretty'
  },
  {
    image: eleven,
    description: 'The first day I ever saw you cry, also the day we had our first big argument, also the day we got our pedicures done'
  },
  {
    image: twelve,
    description: 'You said you loved how your booy looked in this video, I also loved how it looked, infact I love how your booty looks all the time'
  },
  {
    image: thirteen,
    description: 'Our first date night since you traveled, I am hoping we have more of this, even if we slept off some minutes into the movie 😂'
  }
]